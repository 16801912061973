import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col, Table } from "react-bootstrap"
import { Link } from "gatsby"
import ImageDownload from "../../../components/ImageDownload"


const IndexPage = () => {
    const root = "/img/download/awards"

    return (
        <Layout pageTitle="Awards - Templates">
            <Row>
                <Col>
                    <Link to="/awards/sme-awards">Back</Link>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col md={8}>

                <h1>Nominee Templates</h1>

                    
                    <p className="para">
                    Transparent background templates you can insert your image into to post onto your social channels. 
                    Shout about your achievements, raise your profile and promote yourself as an award nominee or award finalist!
                     </p>
                </Col>
            </Row>

            <ImageDownload
                root={root}
                name="Award Nominee Template"
                filename="award-nominee-template-square"
                text="Use this template to announce your nomination in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAEiHGbOHAM/-f-07BXv-V5cMpgYU9mG4Q/view?utm_content=DAEiHGbOHAM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFeZTlDnsA/XkIh4RGXJXijcoLu9-RHtA/view?utm_content=DAFeZTlDnsA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/142VBMQ97ctRchi_se6GrasXenfB0mY19jbY3PmUaByI/edit?usp=sharing"
            />

            <ImageDownload
                root={root}
                name="Award Finalist Template"
                filename="award-finalist-template-square"
                text="Use this template to announce yourself as a finalist in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAEiHGbOHAM/-f-07BXv-V5cMpgYU9mG4Q/view?utm_content=DAEiHGbOHAM&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFeZTlDnsA/XkIh4RGXJXijcoLu9-RHtA/view?utm_content=DAFeZTlDnsA&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/142VBMQ97ctRchi_se6GrasXenfB0mY19jbY3PmUaByI/edit?usp=sharing"

            />

            <Row className="mt-7">
                <Col md={8}>

                <h1>Sponsor Templates</h1>

                    
                    <p className="para">
                       Use these templates to create images specific to your sponsored award and your audience. 
                    </p>
                </Col>
            </Row>


            <Row className="mt-5">
                <Col md={6}>
                    <img
                        src={`/img/wbc-conference-html-email.png`}
                        className="img-fluid"
                        alt=""
                        style={{ maxHeight: "350px" }}
                    />
                </Col>
                <Col className="mt-5">
                    <h3>Email Template</h3>
                    <p className="para">
                        Use this template to email your audience about your award sponsorship and encourage your audience to get involved!
                    </p>
                    <a
                        href="https://docs.google.com/document/d/1VROHkWoxPYuHp6Hwf-zCqCYShO3zc6ubgUmi7dYa6b4/edit?usp=sharing"
                        className="btn btn-primary m-3"
                        rel="noopener noreferrer"
                        target="_blank"
                    >
                        View Template
                    </a>
                </Col>
            </Row>

            <ImageDownload
                root={root}
                name="Sponsor Images"
                filename="award-sponsor"
                text="Use this template to anounce your sponsorship in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAFRjuy6CZw/MPtM9roYkYgzD32-ghF_7g/view?utm_content=DAFRjuy6CZw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref=""
                copyHref="https://docs.google.com/document/d/1q3yNuyC7yzPsb-JUxVivlBh1_RjowEf04n57CTSBseo/edit?usp=sharing"
            />

            <ImageDownload
                root={root}
                name="Individual Award Promo"
                filename="sme-award-promo"
                text="Use this template to shout about your award in the Women's Business Awards"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAFefTZ-WSg/Ha9-wF9xUYY7LGlNm1F_CA/view?utm_content=DAFefTZ-WSg&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFIQgBdwCU/eciMuZ-dAfx4uEMbNpHsEg/view?utm_content=DAFIQgBdwCU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/1q3yNuyC7yzPsb-JUxVivlBh1_RjowEf04n57CTSBseo/edit?usp=sharing"

            />

<ImageDownload
                root={root}
                name="Winner Images"
                filename="award-winner"
                text="Use this template to anounce the winners of your sponsored award"
                text2="You can use or adapt the ready-to-go copy below to write your caption! Remember to tag us and the sponsor (if applicable)"
                squareHref="https://www.canva.com/design/DAFOompL5fo/mZ6YFTTUA5vN_37ZKMk9lA/view?utm_content=DAFOompL5fo&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref=""
                copyHref="https://docs.google.com/document/d/1q3yNuyC7yzPsb-JUxVivlBh1_RjowEf04n57CTSBseo/edit?usp=sharing"
            />

<Row className="mt-7">
                <Col md={8}>

                <h1>General Templates</h1>

                    
                    <p className="para">
                       Use these templates to create images specific to your award and your audience. 
                    </p>
                </Col>
            </Row>

            <ImageDownload
                root={root}
                name="Award Countdown"
                filename="sme-award-countdown-square"
                text="Use this template to count down to the Women's Busines Awards. Don't forget to use your unique URL if you have one! "
                text2="&quot; There are only 10 days left to go until the Women's Business Awards (location)! &quot; Adapt to suit the template and ready-to-go marketing copy below!"
                squareHref="https://www.canva.com/design/DAFefXvLfaw/EmSDwZ6m8bR0cOr9riBxkg/view?utm_content=DAFefXvLfaw&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                horizontalHref="https://www.canva.com/design/DAFTgvRLoiU/xn-6jPYAgn2pVkUYu2LRfQ/view?utm_content=DAFTgvRLoiU&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink&mode=preview"
                copyHref="https://docs.google.com/document/d/10puqRIqzuL1TfqG1yAitHJGZhCpBrmJrJVIRgRuz6AQ/edit?usp=sharing"

            />

           
        </Layout>
    )
}

export default IndexPage
